import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonButton } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { mail, chatbubbles, cash} from 'ionicons/icons';
import './Home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="jcf-header">
          <div slot="start"><img className="jcf-header-icon" src="assets/jc_logo.svg" /></div>

          <IonButton size="small" slot="primary" color="primary" href="mailto:photography@jcorralesfer.com?subject=Photo Restoration">
              
              Send Email
            </IonButton>
        </IonToolbar>
      </IonHeader>
      {/* <IonContent fullscreen> */}
      <IonContent fullscreen >
        <div className="jcf-content">
          <div className="jcf-hero">
            <img className="photo_icon" src="assets/jcf_photo_icon.png" />
            <h1>Bring your old photos <br/>back to life.</h1>
            <p>All the details back to life.</p>
            <img src="assets/hero_restored.jpg" />
            
          </div>
          <IonCard>
              <img src="assets/restored1_before_after_op.jpg" />
              <IonCardHeader>
                <img className="restoring_icon" src="assets/restoring_icon.png" />
                {/* <IonCardSubtitle>Destination</IonCardSubtitle> */}
                <IonCardTitle className="jfc-card-title">Get your old photos restored with the most advanced techniques and tools.</IonCardTitle>
              </IonCardHeader>
          </IonCard>
          <IonCard>
              <img src="assets/restored2_before_after_op.jpg" />
              <IonCardHeader>
              <img className="restoring_icon" src="assets/restoring_icon.png" />
                {/* <IonCardSubtitle>Destination</IonCardSubtitle> */}
                <IonCardTitle className="jfc-card-title">Reconstruction of the missing parts to bring the complete photo back to life.</IonCardTitle>
              </IonCardHeader>
              
          </IonCard>
          <div className="jcf-footer">
            <IonIcon className="jcf-footer-icon" icon={cash} />
            <h1>Pricing</h1>
            <p className="p-first-child">Starts at only <b>$35</b></p>
            <span className="span">Price varies depending on the condition of the photo.</span>
            <IonIcon className="jcf-footer-icon icon-last-child" icon={chatbubbles} />
            <h1>Contact Me</h1>
            <p>photography@jcorralesfer.com</p>
            
            <IonButton color="primary" href="mailto:photography@jcorralesfer.com?subject=Photo Restoration">
              <IonIcon slot="start" icon={mail} />
              Send Email
            </IonButton>
    
          </div>
        </div>

        {/* <ExploreContainer /> */}
        
      </IonContent>
    </IonPage>
  );
};

export default Home;
